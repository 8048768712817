import React from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import Tools from "../classes/tools";

const PorqueCompartir = ({ s }) => {
	const intl = useIntl();

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="why-share">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.porquecompartir?.title)} />
					<main className="page">
						<article className="bg-img">
							<h1 className="white-text prequel-font">{Tools.convertString(s.porquecompartir?.header_title)}</h1>
							<picture>
								<source srcSet="/img/bg/why-share-desktop.jpg" media="(min-width: 768px)" />
								<img srcSet="/img/bg/why-share.jpg" alt={``} />
							</picture>
						</article>

						<article className="box bg-white text">
							<ul className="breadcrumbs white-bg">
								<li className="link">
									<Link to="/" className="ltblue-text-02">
										Home
									</Link>
								</li>
								<li className="active">
									<p className="lightgray-text-07">{Tools.convertString(s.porquecompartir?.title)}</p>
								</li>
							</ul>
							<p className="box">{Tools.convertString(s.porquecompartir?.text)}</p>
						</article>

						<article className="box list">
							<ul>
								<li>
									<div className="wrapper">
										<span className="red-bg white-text saint-font">1</span>
										<h4 className="red-text prequel-font">{Tools.convertString(s.porquecompartir?.item1_title)}</h4>
									</div>
									<p>{Tools.convertString(s.porquecompartir?.item1_text)}</p>
								</li>
								<li>
									<div className="wrapper">
										<span className="red-bg white-text saint-font">2</span>
										<h4 className="red-text prequel-font">{Tools.convertString(s.porquecompartir?.item2_title)}</h4>
									</div>
									<p>{Tools.convertString(s.porquecompartir?.item2_text)}</p>
								</li>
								<li>
									<div className="wrapper">
										<span className="red-bg white-text saint-font">3</span>
										<h4 className="red-text prequel-font">{Tools.convertString(s.porquecompartir?.item3_title)}</h4>
									</div>
									<p>{Tools.convertString(s.porquecompartir?.item3_text)}</p>
								</li>
								<li>
									<div className="wrapper">
										<span className="red-bg white-text saint-font">4</span>
										<h4 className="red-text prequel-font">{Tools.convertString(s.porquecompartir?.item4_title)}</h4>
									</div>
									<p>{Tools.convertString(s.porquecompartir?.item4_text)}</p>
								</li>
							</ul>
						</article>

						<article className="blue-bg legend">
							<h5 className="box margin-auto white-text prequel-font">
								{Tools.convertString(s.porquecompartir?.end_text)}
							</h5>
						</article>
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(PorqueCompartir);
